<template>
<v-row>
  <v-col cols="12" sm="4">
    <lookup-dominio label="* Tipo Item"
                    :hidden-clear="true"
                    hint="Selecione o tipo do Contratado"
                    v-model="value.idTipoItem"
                    :type="lookups.tipoItem"
                    :rules="[
                      this.$validators.notNullOrUndefined
                    ]"/>
  </v-col>   
  <v-col cols="12" md="4">
    <v-text-field label="* Código"
                  dense
                  placeholder="99.99.99.99999999-9"
                  :rules="[
                      this.$validators.string.required,
                      v => this.$validators.string.lessThanOrEquals(v, 20)
                  ]"
                  v-model.trim="value.cdItem"/>
  </v-col>
  <v-col cols="12" md="4">
    <search-box
        label="* Unidade de Medida"
        popup-label="Selecione uma Unidade de Medida"
        :id.sync="value.idUnidadeMedida"
        :descricaoFormatada.sync="value.descricaoUnidadeMedida"
        :hidden-clear="false"
        :value="unidadeMedida"
        :loader-fn="loaderUnidadeMedida"
        item-key="id"
        item-text="descricaoFormatada"
        :rules="[
            this.$validators.notNullOrUndefined
        ]">
      <template v-slot:empty>
        <p class="ma-2">
          Nenhuma Unidade de Medida encontrada
        </p>
      </template>
    </search-box>
  </v-col>
  <v-col cols="12" sm="12">
    <v-textarea 
                dense
                rows="6"
                label="* Nome"
                :rules="[
                      this.$validators.string.required,
                          v => this.$validators.string.greaterThanOrEquals(v, 1),
                          v => this.$validators.string.lessThanOrEquals(v, 4000)
                  ]"
                v-model.trim="value.descricao"/>
  </v-col>
</v-row>
</template>

<script>
import {ItemDeDominio} from "../../../api/itemsDominio";
import LookupDominio from "../common/LookupDominio";
import SearchBox from "../common/SearchBox";
import {findAll as findAllUnidadeMedida} from "../../../api/unidadeMedida";

export default {
  name: "ItemForm",
  components: {LookupDominio,SearchBox},
  props: {
    value: {
      type: Object
    }
  },
  data() {
    return {
      lookups: {
        tipoItem: ItemDeDominio.TIPO_ITEM 
      },
    }
  },
  computed: {
    unidadeMedida() {
      if (this.value.idUnidadeMedida == null) return null;
      return {
        id: this.value.idUnidadeMedida,
        descricaoFormatada: this.value.descricaoUnidadeMedida
      }
    },
  },
  methods: {
    loaderUnidadeMedida(page, search) {
      return findAllUnidadeMedida(page, {
        cdUnidadeMedida: search,
        descricao: search,
      })
    },
  }
}
</script>

<style scoped>

</style>